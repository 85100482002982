import { Layout, Title } from "@components";
import { RouteLocation } from "@interfaces";
import React from "react";

interface Props {
    location: RouteLocation;
}

const Homepage = ({ location }: Props): JSX.Element => (
    <Layout lang="nl" title={`Home`} location={location}>
        <main>
            <div className="container-lg text-center">
                <Title tag="h1">Hallo CV Generator</Title>
                <p>
                    Dit is de frontend van de CV generator. Om de team pages te
                    bekijken, copy-paste de <b>unique team slug</b> in de url.
                </p>
            </div>
        </main>
    </Layout>
);
export default Homepage;
